@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap');

.home-outer-container {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.home-hero-container {
    width: 100%;
    min-height: 460px;
    height: calc(100dvh - 86px);

    display: flex;
}

@media (max-width: 768px) {
    .home-hero-container {
        height: calc(100dvh - 56px);
    }
}

.home-hero-content {
    width: 100%;
    box-sizing: border-box;
    margin: auto 0;
    padding: 0 100px;

    transform: translateY(-54px);

    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .home-hero-content {
        padding: 0 16px;
        margin: auto 0;

        transform: translateY(0px)
    }
}

.home-hero-content h1 {
    width: 100%;
    color: white;
    font-size: 24px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    margin: 0;
    margin-bottom: 100px;
}

@media (max-width: 768px) {
    .home-hero-content h1 {
        margin-bottom: 64px;
    }
}

.home-hero-content p {
    width: 100%;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    margin: 0;
    margin-top: 64px;
    margin-bottom: 100px;

    display: none;
}

@media (max-width: 768px) {
    .home-hero-content p {
        display: flex;
    }
}

.home-hero-content-logo {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
}

.partners-scrolling-outer-wrapper {
    overflow-x: hidden;
    margin-left: -100px;
    margin-right: -100px;
}

@media (max-width: 768px) {
    .partners-scrolling-outer-wrapper {
        margin-left: -16px;
        margin-right: -16px;
    }
}

.partners-scrolling-container {
    display: flex;
    width: fit-content;
    animation: scroll 10s linear infinite;
    gap: 24px;

    overflow-x: visible;
}

.home-hero-container .partners-scrolling-container {
    display: none;
}

@media (max-width: 768px) {
    .home-hero-container .partners-scrolling-container {
        display: flex;
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-50% - 12px));
    }
}

.partners-scrolling-wrapper {
    display: flex;
    width: 100%;
}

.partners-scrolling-container svg {
    flex-shrink: 0;
}

.home-hero-content button {
    display: flex;
    margin: auto 0;
    border: 0;
    padding: 4px;
    background: none;
    cursor: pointer;

    justify-content: center;

    transform: translateY(70px);
}

@media (max-width: 768px) or (max-height: 550px) {
    .home-hero-content button {
        display: none;
    }
}

.home-clients-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0px 100px 100px 100px;
    gap: 40px;

    flex-direction: column;
}

@media (max-width: 768px) {
    .home-clients-container {
        display: none;
    }
}

.home-about-container {
    width: 100%;
    display: flex;
    padding: 100px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    align-self: stretch;
    background: #FFF;
}

@media (max-width: 768px) {
    .home-about-container {
        display: flex;
        padding: 80px 16px;
        flex-direction: column;
        align-items: center;
        gap: 64px;
        align-self: stretch;
    }
}

.home-about-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 100px;
    align-self: stretch;
}

@media (max-width: 768px) {
    .home-about-content {
        flex-direction: column;
        gap: 64px;
    }
}

.home-about-content1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
}

.home-about-content1-text {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

.home-outer-container .home-about-header-text { 
    margin: 0;
    width: 100%;
}

.home-outer-container .home-about-header-text .text-default {
    color: #000;

    font-family: "Instrument Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;

    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .home-outer-container .home-about-header-text .text-default {
        align-self: stretch;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -1.6px;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.home-outer-container .home-about-header-text .text-italic {
    font-style: italic;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.learn-what-we-do-btn {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #000;
    text-decoration: none;
    background-color: transparent;
    transition: background-color 300ms ease;
    /* Smooth transitions */
}

.learn-what-we-do-btn:hover {
    background-color: #000;
    cursor: pointer;
}

.learn-what-we-do-btn span {
    transition: color 300ms ease;
    /* Smooth transition for text color */
}

.learn-what-we-do-btn:hover span {
    color: white;
}

.learn-what-we-do-btn svg path {
    transition: fill 300ms ease;
    /* Smooth transition for SVG path fill */
}

.learn-what-we-do-btn:hover svg path {
    fill: white;
}

.learn-what-we-do-btn span {
    color: #000;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.72px;
}

.home-about-content2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
}

.home-about-content2 span {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 28.8px */
    letter-spacing: -0.48px;
}

.home-about-content2 p {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    letter-spacing: -0.36px;
}

.home-past-work-wrapper-desktop {
    width: calc(100% + 200px);
    height: 420px;
    box-sizing: border-box;
    display: flex;

    margin: 0 -100px;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .home-past-work-wrapper-desktop {
        display: none;
    }
}

.home-past-work-wrapper-mobile {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

@media (max-width: 768px) {
    .home-past-work-wrapper-mobile {
        display: flex;
    }
}

.home-past-work-container {
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    will-change: 'transform'
}

.home-services-container {
    display: flex;
    padding: 100px;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--primary-color);
    gap: 100px;
}

.home-services-content1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    order: 1;

    width: 50%;
}

.home-services-content2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 32px;
    order: 2;

    width: 40%;
}

@media (max-width: 768px) {

    .home-services-container {
        flex-direction: column;
        padding: 80px 16px;
        gap: 80px;
    }

    .home-services-content1 {
        order: 2;

        width: 100%;
    }

    .home-services-content2 {
        order: 1;
        align-items: flex-start;
        justify-content: left;

        width: 100%;
    }
}

.home-services-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
}

@media (max-width: 768px) {
    .home-services-header {
        align-items: flex-start;
    }
}

.home-services-header span {
    align-self: stretch;
    color: #fff;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

@media (max-width: 768px) {
    .home-services-header span {
        text-align: left;
    }
}

.home-outer-container h2 { 
    margin: 0;
    width: 100%;
    text-align: right;
}

@media (max-width: 768px) {
    .home-outer-container h2 {
        text-align: left;
    }
}

.home-outer-container h2 .text-default {
    color: #fff;

    font-family: "Instrument Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;

    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .home-outer-container h2 .text-default {
        align-self: stretch;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -1.6px;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.home-outer-container h2 .text-italic {
    font-style: italic;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.home-hero-content h2 {
    width: 100%;
    word-wrap: break-word;
    text-align: right;
}

@media (max-width: 768px) {
    .home-hero-content h2 {
        text-align: left;
    }
}

.home-hero-content h2 .text-default {
    color: #fff;
    font-size: 56px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    line-height: 61.6px;
}

@media (max-width: 768px) {
    .home-hero-content h2 .text-default {
        font-size: 40px;
        line-height: 44px;
    }
}

.home-hero-content h2 .text-italic {
    font-style: italic;
}

.home-services-content2 a {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #fff;
    text-decoration: none;
    transition: background-color 300ms ease;
}

.home-services-content2 a span {
    color: #fff;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.home-services-content2 a:hover {
    background-color: #fff;
    cursor: pointer;
}

.home-services-content2 a:hover span {
    color: var(--primary-color);
}

.home-services-content2 a svg path {
    transition: fill 300ms ease;
}

.home-services-content2 a:hover svg path {
    fill: var(--primary-color);
}

.home-why-subtle-container {
    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;
    background: #FFF;
}

@media (max-width: 768px) {
    .home-why-subtle-container {
        padding: 80px 16px;
    }
}

.home-why-subtle-content1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.home-why-subtle-content1 span {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

.home-outer-container h3 {
    width: 100%;
    word-wrap: break-word;
    margin: 0;
}

.home-outer-container h3 .text-default {
    color: #000;
    font-size: 56px;
    font-style: normal;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;
}

@media (max-width: 768px) {
    .home-outer-container h3 .text-default {
        font-size: 40px;
        line-height: 44px;
        letter-spacing: -1.6px;
    }
}

.home-outer-container h3 .text-italic {
    font-style: italic;
}

.home-why-subtle-content2 {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    flex-direction: row;
}

@media (max-width: 768px) {
    .home-why-subtle-content2 {
        flex-direction: column;
        align-items: flex-start;
        gap: 64px;
    }
}

.home-why-subtle-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
}

.home-why-subtle-content-wrapper span {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;
}

.home-why-subtle-content-wrapper p {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    letter-spacing: -0.36px;
}

.home-why-subtle-container a {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #000;
    text-decoration: none;
    transition: background-color 300ms ease;
}

.home-why-subtle-container a span {
    color: #000;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.home-why-subtle-container a:hover {
    background-color: #000;
    cursor: pointer;
}

.home-why-subtle-container a:hover span {
    color: #fff;
}

.home-why-subtle-container a svg path {
    transition: fill 300ms ease;
}

.home-why-subtle-container a:hover svg path {
    fill: #fff;
}

.home-process-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    align-self: stretch;
    background-color: var(--primary-color);
}

@media (max-width: 768px) {
    .home-process-container {
        padding: 80px 16px;
        gap: 64px;
    }
}

.home-process-container a {
    text-decoration: none;

    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 100px;
    border: 1px solid #FFF;

    transition: background-color 300ms ease;
}

.home-process-container a:hover {
    background-color: #FFF;
    cursor: pointer;
}

.home-process-container a span {
    color: #FFF;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.home-process-container a:hover span {
    color: var(--primary-color);
}

.home-process-container a svg path {
    transition: fill 300ms ease;
}

.home-process-container a:hover svg path {
    fill: var(--primary-color);
}

.home-process-content1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.home-process-content1 span {
    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

.home-outer-container h4 {
    width: 100%;
    word-wrap: break-word;
    margin: 0;
}

.home-outer-container h4 .text-default {
    color: white;
    font-size: 56px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    line-height: 61.6px;
}

@media (max-width: 768px) {
    .home-outer-container h4 .text-default {
        font-size: 40px;
        line-height: 44px;
    }
}

.home-outer-container h4 .text-italic {
    font-style: italic;
}

.home-process-content2 {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    flex-direction: row;
}

@media (max-width: 768px) {
    .home-process-content2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }
}

.home-process-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
}

.home-process-wrapper-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.home-process-wrapper-text span {
    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

.home-process-wrapper-text div {
    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;
}

.home-process-wrapper-text p {
    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    letter-spacing: -0.36px;
}

.home-faq-container {
    display: flex;
    padding: 100px;
    flex-direction: row;
    gap: 48px;
    background: #FFF;
    align-items: flex-start;
    gap: 100px;
    align-self: stretch;
}

.home-faq-content1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;

    order: 1;

    width: 50%;
}

.home-faq-content2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 32px;

    order: 2;

    width: 40%;
}

@media (max-width: 768px) {

    .home-faq-container {
        flex-direction: column;
        padding: 80px 16px;
        gap: 80px;
    }

    .home-faq-content1 {
        order: 2;
        width: 100%;
        gap:24px;
    }

    .home-faq-content2 {
        order: 1;
        align-items: flex-start;
        justify-content: left;

        width: 100%;
    }
}

.home-faq-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
}

@media (max-width: 768px) {
    .home-faq-header {
        align-items: flex-start;
    }
}

.home-faq-header span {
    align-self: stretch;
    color: #000;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

@media (max-width: 768px) {
    .home-faq-header span {
        text-align: left;
    }
}

.home-outer-container h5 {
    width: 100%;
    margin: 0;
    word-wrap: break-word;
    text-align: right;
}

@media (max-width: 768px) {
    .home-outer-container h5 {
        text-align: left;
    }
}

.home-outer-container h5 .text-default {
    color: #000;
    font-size: 56px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    line-height: 61.6px;
}

@media (max-width: 768px) {
    .home-outer-container h5 .text-default {
        font-size: 40px;
        line-height: 44px;
    }
}

.home-outer-container h5 .text-italic {
    font-style: italic;
}

.home-faq-content2 a {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #000;
    text-decoration: none;
    transition: background-color 300ms ease;
}

.home-faq-content2 a span {
    color: #000;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.home-faq-content2 a:hover {
    background-color: #000;
    cursor: pointer;
}

.home-faq-content2 a:hover span {
    color: #fff;
}

.home-faq-content2 a svg path {
    transition: fill 300ms ease;
}

.home-faq-content2 a:hover svg path {
    fill: #fff;
}