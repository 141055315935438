@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap');

.coming-soon-contact-us-btn {
    background-color: var(--primary-color);
    color: white;
    box-sizing: border-box;
    padding: 14px 40px;
    border-radius: 100px;
    border: 1px solid white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: background-color 300ms ease-in-out;

    text-decoration: none;
}

.coming-soon-contact-us-btn:hover {
    cursor: pointer;
    background-color: white;
    color: var(--primary-color);

    text-decoration: none;
}

.coming-soon-contact-us-btn div {
    text-align: right;
    color: white;
    font-size: 18px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 500;

    transition: color 300ms ease-in-out;
}

.coming-soon-contact-us-btn:hover div {
    color: var(--primary-color);
}

.coming-soon-contact-us-btn svg path {
    fill: white;

    transition: fill 300ms ease-in-out;
}

.coming-soon-contact-us-btn:hover svg path {
    fill: var(--primary-color);
}

.coming-soon-outer-container {
    position:relative;
    box-sizing: border-box;
    width: 100%;
    
    padding: 80px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 40px;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .coming-soon-outer-container {
        padding: 40px 24px;
    }
}

.coming-soon-footer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    margin: 0;
}

@media (max-width: 768px) {
    .coming-soon-footer {
        flex-direction: column;
        gap: 24px;
        margin-right: auto;
    }
}

.coming-soon-content-frame {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
}

@media (max-width: 768px) {
    .coming-soon-content-frame {
        flex-direction: column;
        gap: 24px;
    }
}

.coming-soon-content-frame div {
    width: 80%;
    height: 200px;
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 0 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 768px) {
    .coming-soon-content-frame div {
        padding: 0;
        height: auto;
    }
}