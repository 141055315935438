@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap');

.services-outer-container {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;

    background-color: var(--primary-color);
}

.services-hero-container {
    min-height: 460px;
    height: calc(100dvh - 86px);

    display: flex;
    padding: 0 100px;

    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
}

@media (max-width: 768px) {
    .services-hero-container {
        padding: 80px 16px;
        gap: 48px;

        min-height: fit-content;
        height: auto;
    }
}

.services-hero-container div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.services-hero-container span {
    margin: 0;
    padding: 0;

    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
}

.services-outer-container h1 {
    margin: 0;
    width: 100%;
}

.services-outer-container h1 .text-default {
    color: #FFFFFF;

    font-family: "Instrument Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;

    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .services-outer-container h1 .text-default {
        align-self: stretch;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -1.6px;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.services-outer-container h1 .text-italic {
    font-style: italic;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.services-content-outer-container {
    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: #FFF;
    flex-direction: row;
}

@media (max-width: 768px) {
    .services-content-outer-container {
        padding: 80px 16px;
    }
}

.services-content-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 100px;
    align-self: stretch;
    flex-direction: row;
}

@media (max-width: 768px) {
    .services-content-wrapper {
        gap: 64px;
        flex-direction: column;
    }
}

.services-content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 32px;
    flex: 1 0 0;

    order: 2;
}

@media (max-width: 768px) {
    .services-content-header {
        order: 1;
        align-items: flex-start;

    }
}

.services-content-header div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.services-content-header span {
    align-self: stretch;
    color: #000;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

@media (max-width: 768px) {
    .services-content-header span {
        text-align: left;
    }
}

.services-outer-container h2 {
    margin: 0;
    width: 100%;

    text-align: right;
}

@media (max-width: 768px) {
    .services-outer-container h2 {
        text-align: left;
    }
}

.services-outer-container h2 .text-default {
    color: black;

    font-family: "Instrument Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;

    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .services-outer-container h2 .text-default {
        align-self: stretch;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -1.6px;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.services-outer-container h2 .text-italic {
    font-style: italic;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.services-content-header a {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid black;
    text-decoration: none;
    transition: background-color 300ms ease;

    width: 139px;
}

.services-content-header a span {
    color: black;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.services-content-header a:hover {
    background-color: black;
    cursor: pointer;
}

.services-content-header a:hover span {
    color: white;
}

.services-content-header a svg path {
    transition: fill 300ms ease;
}

.services-content-header a:hover svg path {
    fill: white;
}



.this-page-services-wrapper {
    display: flex;
    padding: 0;
    padding-bottom: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
    align-self: stretch;
    border: 0;
    border-bottom: 1px solid #000;
    background-color: transparent;
    text-align: left;

    cursor: pointer;
    transition: gap 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .this-page-services-wrapper {
        padding-bottom: 24px;
    }
}

.this-page-services-wrapper.active {
    gap: 16px;
}

.this-page-services-header-wrapper {
    display: flex;
    justify-content: right;
    gap: 40px;
    align-self: stretch;
}

.this-page-services-header-wrapper span {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    width: 100%;
}

.this-page-services-wrapper p {
    margin: 0;
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.36px;

    display: none;
    opacity: 0;

    transition: display 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.this-page-services-wrapper p.active {
    display: flex;
    opacity: 1;
    /* Allow opacity to transition smoothly when active */
    transition: display 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.this-page-services-wrapper .this-page-plusminus {
    position: relative;
    width: 24px;
    height: 24px;

    &.active {
        &:before {
            transform: translatey(-50%) rotate(-90deg);
            opacity: 0;
        }

        &:after {
            transform: translatey(-50%) rotate(0);
        }
    }

    &:before,
    &:after {
        content: "";
        display: block;
        background-color: #000;
        position: absolute;
        top: 50%;
        left: 0;
        transition: .35s;
        width: 17.62px;
        height: 2px;
    }

    &:before {
        transform: translatey(-50%);
    }

    &:after {
        transform: translatey(-50%) rotate(90deg);
    }
}

.services-list-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;

    order: 1;
}

@media (max-width: 768px) {
    .services-list-header {
        order: 2;
        gap: 24px;
    }
}

.services-packages-container {
    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    align-self: stretch;
    background: #FF3D00;
}

@media (max-width: 768px) {
    .services-packages-container {
padding: 80px 16px;
gap: 64px;
    }
}

.services-packages-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.services-packages-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.services-packages-header span {
    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
}

.services-packages-header h3 {
    margin: 0;
    width: 100%;
}

.services-packages-header h3 .text-default {
    color: white;

    font-family: "Instrument Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;

    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .services-packages-header h3 .text-default {
        align-self: stretch;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -1.6px;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.services-packages-header h3 .text-italic {
    font-style: italic;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.services-packages-content-container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    border-radius: 24px;
    background: transparent;
}

.services-packages-list-content {
    display: flex;
    align-items: stretch;
    gap: 40px;
    align-self: stretch;
    width: 100%;
    height: fit-content;

    overflow-x: auto;
    flex-direction: row;
}

@media (max-width: 768px) {
    .services-packages-list-content {
        overflow-x: hidden;
        flex-direction: column;
    }
}

.services-packages-content-container.active {
    background: white;
}

.services-packages-content-container a {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #FFFFFF;
    background-color: transparent;
    text-decoration: none;
    transition: background-color 300ms ease;

    width: 139px;
}

.services-packages-content-container a span {
    color: #FFFFFF;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.services-packages-content-container a:hover {
    background-color: white;
    cursor: pointer;
}

.services-packages-content-container a:hover span {
    color: var(--primary-color);
}

.services-packages-content-container a svg path {
    fill: white;
    transition: fill 300ms ease;
}

.services-packages-content-container a:hover svg path {
    fill: var(--primary-color);
}

.services-packages-content-container.active a {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--primary-color);
    background-color: transparent;
    text-decoration: none;
    transition: background-color 300ms ease;

    width: 139px;
}

.services-packages-content-container.active a span {
    color: var(--primary-color);
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.services-packages-content-container.active a:hover {
    background-color: var(--primary-color);
    cursor: pointer;
}

.services-packages-content-container.active a:hover span {
    color: white;
}

.services-packages-content-container.active a svg path {
    fill: var(--primary-color);
    transition: fill 300ms ease;
}

.services-packages-content-container.active a:hover svg path {
    fill: white
}

.services-packages-content-container.active svg path {
    fill: var(--primary-color);
}

.services-packages-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.services-packages-content-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.services-packages-content-header span {
    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;

    height: 79.36px;
}

@media (max-width: 768px) {
    .services-packages-content-header span {
        height: auto;
    }
}

.services-packages-content-header p {
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    letter-spacing: -0.36px;
    margin: 0;
    height: 108px;
}

@media (max-width: 768px) {
    .services-packages-content-header p {
        height: auto;
    }
}

.services-packages-content-container.active .services-packages-content-header span {
    align-self: stretch;
    color: var(--primary-color);
    font-family: "Instrument Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;
}

.services-packages-content-container.active .services-packages-content-header p {
    color: var(--primary-color);
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    letter-spacing: -0.36px;
}

.services-packages-content-benefits-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-bottom: auto;

    height: 283.4px;
}

@media (max-width: 768px) {
    .services-packages-content-benefits-list {
        height: auto;
    }
}

.services-packages-content-benefits-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    color: #FFF;
}

.services-packages-content-container.active .services-packages-content-benefits-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    color: var(--primary-color);
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 21.6px */
    letter-spacing: -0.36px;
}

.services-packages-content-benefits-wrapper span {
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 21.6px */
    letter-spacing: -0.36px;
    flex: 1 0 0;
}

.services-placeholder-section{
    height: 100px;
    background-color: white;
}

@media (max-width: 768px) {
    .services-placeholder-section{
        height: 80px;
    }
    
}