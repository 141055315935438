.footer {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    padding: 0px 100px 100px 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    align-self: stretch;
}

@media (max-width: 768px) {
    .footer {
        padding: 0px 16px 80px 16px;
        gap: 64px;
    }
}

.footer-nav-btn {
    text-align: center;
    color: #FF3D00;
    font-size: 18px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    transition: color 200ms ease-in-out;

    background-color: transparent;
    border: 0;

    padding: 0;
}

.footer-nav-btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
}

@media (max-width: 768px) {
    .footer-nav-btn-container {
        flex-direction: column;
        gap: 16px;
    }
}

.footer-nav-btn:hover {
    color: #ed9986;
    cursor: pointer;
}

.footer-nav-svg-btn {
    cursor: pointer;
}

.footer-nav-svg-btn path {
    transition: fill 200ms ease-in-out;
}

.footer-nav-svg-btn:hover path {
    fill: #ed9986;
}

.footer-soc-med-container-desktop {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
}

@media (max-width: 768px) {
    .footer-soc-med-container-desktop {
        display: none;
    }
}

.footer-soc-med-container-mobile {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    display: none;
}

@media (max-width: 768px) {
    .footer-soc-med-container-mobile {
        display: flex;
    }
}

.footer-logo-container-mobile {
    width: 100%;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    padding: 0;
    padding-right: 100px;
    padding-left: 100px;
}

@media (max-width: 768px) {
    .footer-logo-container-mobile {
        display: flex;
    }
}

.footer-logo-container-desktop {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 60px;
}

@media (max-width: 768px) {
    .footer-logo-container-desktop {
        display: none;
    }
}

.footer-email-link {
    color: #FF3D00;
    font-size: 18px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    text-decoration: underline;
    word-wrap: break-word;
    cursor: pointer;
    transition: color 150ms ease-in-out; /* Smooth transition */
}

.footer-email-link:hover {
    color: #ed9986;
}
