@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap');

.faq-wrapper {
    display: flex;
    padding: 0;
    padding-bottom: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
    align-self: stretch;
    border: 0;
    border-bottom: 1px solid #000;
    background-color: transparent;
    text-align: left;

    cursor: pointer;
    transition: gap 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .faq-wrapper {
        padding-bottom: 24px;
    }
}

.faq-wrapper.active {
    gap: 16px;
}

.faq-question-wrapper {
    display: flex;
    justify-content: right;
    gap: 40px;
    align-self: stretch;
}

.faq-question-wrapper span {
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    width: 100%;
}

.faq-wrapper p {
    margin: 0;
    align-self: stretch;
    color: #000;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.36px;
    opacity: 0;
    display: none;

    transition: display 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.faq-wrapper p.active {
    opacity: 1;
    display: flex;

    /* Allow opacity to transition smoothly when active */
    transition: display 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.faq-wrapper .plusminus {
    position: relative;
    width: 24px;
    height: 24px;

    &.active {
        &:before {
            transform: translatey(-50%) rotate(-90deg);
            opacity: 0;
        }

        &:after {
            transform: translatey(-50%) rotate(0);
        }
    }

    &:before,
    &:after {
        content: "";
        display: block;
        background-color: #333;
        position: absolute;
        top: 50%;
        left: 0;
        transition: .35s;
        width: 17.62px;
        height: 2px;
    }

    &:before {
        transform: translatey(-50%);
    }

    &:after {
        transform: translatey(-50%) rotate(90deg);
    }
}