.about-us-outer-container {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
}

.about-us-hero-container {
    min-height: 460px;
    height: calc(100dvh - 86px);

    display: flex;
    padding: 0 100px;

    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
}

@media (max-width: 768px) {
    .about-us-hero-container {
        padding: 80px 16px;
        gap: 48px;

        min-height: fit-content;
        height: auto;
    }
}

.about-us-hero-container div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.about-us-hero-container span {
    margin: 0;
    padding: 0;

    align-self: stretch;
    color: #FFF;
    font-family: "Instrument Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
}

.about-us-outer-container h1 {
    margin: 0;
    width: 100%;
}

.about-us-outer-container h1 .text-default {
    color: #FFFFFF;

    font-family: "Instrument Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;

    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .about-us-outer-container h1 .text-default {
        align-self: stretch;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -1.6px;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.about-us-outer-container h1 .text-italic {
    font-style: italic;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.about-us-content-outer-container {
    background: #FFF;

    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    align-self: stretch;
}

@media (max-width: 768px) {
    .about-us-content-outer-container {
        padding: 80px 16px;
        gap: 64px;
    }
}

.about-us-content-wrapper {
    display: grid;
    grid-auto-columns: minmax(0, 1fr); 
    grid-auto-flow: column; 
    gap: 100px; 
    align-items: start;
    width: 100%; 
}

@media (max-width: 768px) {
    .about-us-content-wrapper {
        grid-auto-flow: row; 
        gap: 32px; 
    }
}

.about-us-content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
}

.about-us-content-header div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.about-us-content-header span {
    color: #000;
    font-family: "Instrument Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
    align-self: stretch;
}

.about-us-outer-container h2 {
    margin: 0;
    width: 100%;

    text-align: left;
}

.about-us-outer-container h2 .text-default {
    color: black;

    font-family: "Instrument Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 61.6px */
    letter-spacing: -2.24px;

    word-wrap: break-word;
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .about-us-outer-container h2 .text-default {
        align-self: stretch;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -1.6px;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.about-us-outer-container h2 .text-italic {
    font-style: italic;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.about-us-content-wrapper p {
    color: #000;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    letter-spacing: -0.36px;
    align-self: stretch;
}

@media (max-width: 768px) {
    .about-us-content-wrapper p {
        font-size: 16px;
        line-height: 140%;

        letter-spacing: -0.32px;
    }
}

.about-us-content-header a {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid black;
    text-decoration: none;
    transition: background-color 300ms ease;

    width: 139px;
}

.about-us-content-header a span {
    color: black;
    text-align: right;
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.72px;
    transition: color 300ms ease;
}

.about-us-content-header a:hover {
    background-color: black;
    cursor: pointer;
}

.about-us-content-header a:hover span {
    color: white;
}

.about-us-content-header a svg path {
    transition: fill 300ms ease;
}

.about-us-content-header a:hover svg path {
    fill: white;
}