:root {
  --primary-color: #FF3D00;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  background-color: var(--primary-color);

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  max-width: 100dvw;
  min-height: 100dvh;
  margin: 0;
  padding: 0;
  display: flex;
}