@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;700&display=swap');

.header-container-desktop {
    width: 100%;
    height: 86px;
    box-sizing: border-box;
    padding: 32px 100px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .header-container-desktop {
        display: none;
    }
}

.header-container-mobile {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    padding: 16px;
    display: none;

    align-items: start;
    align-self: stretch;

    position: sticky;
    position: -webkit-sticky;
    top: 0;

    background-color: var(--primary-color);
    flex-direction: column; 

    overflow-y: hidden;

    z-index: 1000;
}

@media (max-width: 768px) {
    .header-container-mobile {
        display: inline-flex;
    }
}

.header-container-mobile-row1 {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;

    margin-bottom: 24px;
}

.header-container-mobile-row2 {
    width: 100%;
    display: flex;
    flex-direction: column; 
    gap: 40px;

    margin-bottom: 64px;
}

.header-nav-btn {
    text-align: center;
    color: white;
    font-size: 18px;
    font-family: 'Instrument Sans', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    transition: color 300ms ease-in-out;

    background-color: transparent;
    border: 0;
}

.header-nav-btn:hover {
    color: #ed9986;
    cursor: pointer;
}

.header-nav-btn-mobile {
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    background-color: transparent;
    border: 0;
    color: var(--Base-Super-Black-100, #171111);
    font-family: 'Instrument Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
}
